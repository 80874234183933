import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import { useTranslation } from 'react-i18next'
import { useWishlistData } from '../lib/products/wishlist'
import { Link } from 'gatsby'
import { addFavorite, isFavorite } from '../lib/favorites_hook'

const FavoritesImportPage = (props: any) => {
  const sharingCode = props.sharingCode
  const { t } = useTranslation('translation')
  const wishlistItems = useWishlistData({ code: sharingCode })

  if (wishlistItems === undefined) {
    return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <LinearProgress />
            </div>
          </div>
        </div>
    )
  } else {
    let count = 0

    Object.values(wishlistItems).map((wishlistItem: any) => {
      if (
        !isFavorite(wishlistItem.id, wishlistItem.material, wishlistItem.ratio)
      ) {
        addFavorite(wishlistItem.id, wishlistItem.material, wishlistItem.ratio)
        count = count + 1
      }
    })

    const message = t('Es wurden %d Favoriten wiederhergestellt.').replace(
      '%d',
      '' + count,
    )

    return (
      <>
        <h1>{t('Favoriten wiederhergestellt')}</h1>
        <p>{message}</p>
        <Link to="/favoriten">{t('Favoriten')}</Link>
      </>
    )
  }
}

export default FavoritesImportPage
